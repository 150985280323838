import React from 'react'
import { Wrapper } from './styles'

export default ({ desktop }) => (
	<Wrapper desktop={desktop}>
		<a href="/">HOME</a>
		<a href="/about">ABOUT</a>
		<a href="/contact">CONTACT</a>
	</Wrapper>
)
