import React from 'react'
import Header from 'components/theme/Header'
import { Wrapper } from './styles'

export default () => {

  return (
    <Wrapper>
      <Header/>
    </Wrapper>
  )
};
