import React from 'react'
import Container from 'components/common/Container'
import { Details, PrivacyPolicyWrapper, Wrapper } from './styles'

export default () => {
  return (
    <Wrapper id="privacy-policy">
      <PrivacyPolicyWrapper as={Container}>
        <Details>
          <h1>PRIVACY POLICY</h1>
          <p>
            It is CloudBuilder’s policy to respect your privacy regarding any information we may collect while operating
            our website. This Privacy Policy applies to https://www.cloudbuilder.be (hereinafter, "us", "we", or
            "https://www.cloudbuilder.be"). We respect your privacy and are committed to protecting personally
            identifiable information you may provide us through the Website. We have adopted this privacy policy
            ("Privacy Policy") to explain what information may be collected on our Website, how we use this information,
            and under what circumstances we may disclose the information to third parties. This Privacy Policy applies
            only to information we collect through the Website and does not apply to our collection of information from
            other sources.
          </p>
          <p>
            This Privacy Policy, together with the Terms and conditions posted on our Website, set forth the general
            rules and policies governing your use of our Website. Depending on your activities when visiting our
            Website, you may be required to agree to additional terms and conditions.
          </p>
          <h2>WEBSITE VISITORS</h2>
          <p>
            Like most website operators, CloudBuilder BVBA collects non-personally-identifying information of the sort
            that web browsers and servers typically make available, such as the browser type, language preference,
            referring site, and the date and time of each visitor request. CloudBuilder BVBA’s purpose in collecting
            non-personally identifying information is to better understand how CloudBuilder BVBA’s visitors use its
            website. From time to time, CloudBuilder BVBA may release non-personally-identifying information in the
            aggregate, e.g., by publishing a report on trends in the usage of its website.
          </p>
          <p>
            CloudBuilder BVBA also collects potentially personally-identifying information like Internet Protocol (IP)
            addresses for logged in users and for users leaving comments on https://www.cloudbuilder.be blog posts.
            CloudBuilder BVBA only discloses logged in user and commenter IP addresses under the same circumstances that
            it uses and discloses personally-identifying information as described below.
          </p>
          <h2>GATHERING OF PERSONALLY-IDENTIFYING INFORMATION</h2>
          <p>
            Certain visitors to CloudBuilder BVBA’s websites choose to interact with CloudBuilder BVBA in ways that
            require CloudBuilder BVBA to gather personally-identifying information. The amount and type of information
            that CloudBuilder BVBA gathers depends on the nature of the interaction.
          </p>
          <h2>SECURITY</h2>
          <p>
            The security of your Personal Information is important to us, but remember that no method of transmission
            over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
          </p>
          <h2>LINKS TO EXTERNAL SITES</h2>
          <p>
            Our Service may contain links to external sites that are not operated by us. If you click on a third party
            link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy
            and terms and conditions of every site you visit.
          </p>
          <p>
            We have no control over, and assume no responsibility for the content, privacy policies or practices of
            any
            third party sites, products or services.
          </p>
          <h2>AGGREGATED STATISTICS</h2>
          <p>
            CloudBuilder BVBA may collect statistics about the behavior of visitors to its website. CloudBuilder BVBA
            may display this information publicly or provide it to others. However, CloudBuilder BVBA does not
            disclose
            your personally-identifying information.
          </p>
          <h2>COOKIES</h2>
          <p>
            To enrich and perfect your online experience, CloudBuilder BVBA uses "Cookies", similar technologies and
            services provided by others to display personalised content, appropriate advertising and store your
            preferences on your computer.
          </p>
          <p>
            A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor’s
            browser provides to the website each time the visitor returns. CloudBuilder BVBA uses cookies to help
            CloudBuilder BVBA identify and track visitors, their usage of https://www.cloudbuilder.be, and their
            website
            access preferences. CloudBuilder BVBA visitors who do not wish to have cookies placed on their computers
            should set their browsers to refuse cookies before using CloudBuilder BVBA’s websites, with the drawback
            that certain features of CloudBuilder BVBA’s websites may not function properly without the aid of
            cookies.
          </p>
          <p>
            By continuing to navigate our website without changing your cookie settings, you hereby acknowledge and
            agree to CloudBuilder BVBA's use of cookies.
          </p>
          <h2>PRIVACY POLICY CHANGES</h2>
          <p>
            Although most changes are likely to be minor, CloudBuilder BVBA may change its Privacy Policy from time to
            time, and in CloudBuilder BVBA’s sole discretion. CloudBuilder BVBA encourages visitors to frequently
            check
            this page for any changes to its Privacy Policy. Your continued use of this site after any change in this
            Privacy Policy will constitute your acceptance of such change.
          </p>
          <h2>CONTACT INFORMATION</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact us via the form <a
            href="/contact">here</a>.
          </p>
        </Details>
      </PrivacyPolicyWrapper>
    </Wrapper>
  )
};
