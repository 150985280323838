import React from "react";
import colors from "tokens/colors";

export default ({ color = colors.primary }) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
    clipRule="evenodd"
    viewBox="0 0 320 60"
  >
    <path
      fill="#fff"
      d="M354.923 616.588c-36.175-.366-72.015-9.613-103.822-26.886-31.279-16.986-55.557-40.529-76.727-68.852-49.047-65.622-50.97-162.547-12.81-232.814 16.986-31.279 40.529-55.557 68.851-76.727a212.29 212.29 0 0118.964-12.655c29.179-17.269 60.522-26.358 91.919-27.919 17.173-25.44 39.435-48.522 65.486-68.861C489.252 37.485 610.118 1.742 699.135 1.742c55.631 0 94.339 13.648 122.229 34.858 28.324 21.539 47.161 51.732 57.87 91.377 6.51 24.104 9.829 52.648 11.477 85.233a223.748 223.748 0 0136.046 32.299l-.708.636c35.053 39.337 56.361 91.178 56.361 147.955 0 122.801-99.7 222.5-222.5 222.5H354.923v-.012zM524.271 496.6H759.91c56.57 0 102.5-45.928 102.5-102.5 0-26.284-9.92-50.269-26.22-68.414l.704-.633a103.154 103.154 0 00-30.757-23.088c-4.822-2.362-16.53-5.838-26.115-8.661h-7.772l-.001-2.269a232.292 232.292 0 00-4.982-1.371l4.946-18.991c-.139-31.458-.787-59.598-3.775-84.037-2.702-22.094-5.431-40.402-17.024-52.16-10.633-10.784-27.824-12.738-52.279-12.738-66.52 0-156.878 26.602-218.504 74.718-32.791 25.602-58.07 57.259-58.07 96.848h-44.292l-.547 1.637a84.732 84.732 0 00-5.503-1.637h-41.301c-6.984 1.868-13.84 4.724-20.422 8.62a92.485 92.485 0 00-8.239 5.504c-14.213 10.624-26.716 22.179-35.24 37.876-16.982 31.27-18.351 74.502 3.476 103.704 10.623 14.214 22.179 26.716 37.876 35.241 14.933 8.11 31.805 12.351 48.796 12.351h82.572c21.867-.394 43.255-9.751 60.639-25.832l23.895 25.832z"
      transform="matrix(.09758 0 0 .09758 -13.177 -.17)"
    ></path>
    <path
      fill="#fff"
      fillRule="nonzero"
      d="M.332.008a.324.324 0 01-.154-.036.267.267 0 01-.146-.241.267.267 0 01.146-.242.336.336 0 01.154-.035.31.31 0 01.149.035.212.212 0 01.092.102l-.121.065C.424-.393.384-.418.331-.418a.136.136 0 00-.101.04.146.146 0 00-.04.109c0 .046.013.082.04.109.027.027.06.04.101.04a.131.131 0 00.121-.074l.121.066a.212.212 0 01-.092.1.299.299 0 01-.149.036z"
      transform="matrix(.26108 0 0 .26108 -78.555 -286.414) matrix(128 0 0 128 665.137 1261.81)"
    ></path>
    <path
      fill="#fff"
      fillRule="nonzero"
      d="M0.072 -0.742H0.22799999999999998V0H0.072z"
      transform="matrix(.26108 0 0 .26108 -78.555 -286.414) translate(753.073 1261.81) scale(128)"
    ></path>
    <path
      fill="#fff"
      fillRule="nonzero"
      d="M.328.008a.316.316 0 01-.152-.036.257.257 0 01-.106-.098.275.275 0 01-.038-.143.267.267 0 01.144-.242.327.327 0 01.152-.035c.057 0 .107.012.152.035a.264.264 0 01.143.242.275.275 0 01-.038.143.255.255 0 01-.105.098.316.316 0 01-.152.036zm0-.128c.04 0 .073-.013.099-.041a.153.153 0 00.038-.108.155.155 0 00-.038-.109.131.131 0 00-.099-.04.131.131 0 00-.099.04.152.152 0 00-.039.109.15.15 0 00.039.108.128.128 0 00.099.041z"
      transform="matrix(.26108 0 0 .26108 -78.555 -286.414) translate(753.073 1261.81) translate(38.528) scale(128)"
    ></path>
    <path
      fill="#fff"
      fillRule="nonzero"
      d="M.614-.538V0H.466v-.064a.224.224 0 01-.167.072.227.227 0 01-.168-.061C.09-.094.069-.154.069-.234v-.304h.156v.281c0 .087.036.13.109.13a.116.116 0 00.09-.037c.023-.024.034-.06.034-.108v-.266h.156z"
      transform="matrix(.26108 0 0 .26108 -78.555 -286.414) translate(753.073 1261.81) translate(122.368) scale(128)"
    ></path>
    <path
      fill="#fff"
      fillRule="nonzero"
      d="M.62-.742V0H.471v-.062c-.039.047-.095.07-.168.07a.273.273 0 01-.137-.034.245.245 0 01-.097-.097.3.3 0 01-.035-.146.3.3 0 01.035-.146.245.245 0 01.097-.097.273.273 0 01.137-.034c.069 0 .122.022.161.065v-.261H.62zM.33-.12a.128.128 0 00.098-.041.15.15 0 00.039-.108.152.152 0 00-.039-.109.13.13 0 00-.098-.04.131.131 0 00-.099.04.152.152 0 00-.039.109.15.15 0 00.039.108.128.128 0 00.099.041z"
      transform="matrix(.26108 0 0 .26108 -78.555 -286.414) translate(753.073 1261.81) translate(210.304) scale(128)"
    ></path>
    <path
      fill="#fff"
      fillRule="nonzero"
      d="M.39-.546c.05 0 .096.011.137.034a.249.249 0 01.096.097.3.3 0 01.035.146.294.294 0 01-.035.145.236.236 0 01-.096.097.266.266 0 01-.137.035c-.074 0-.13-.023-.169-.07V0H.072v-.742h.156v.261A.208.208 0 01.39-.546zM.363-.12c.04 0 .073-.013.099-.041A.153.153 0 00.5-.269a.155.155 0 00-.038-.109.131.131 0 00-.099-.04.127.127 0 00-.098.04.152.152 0 00-.039.109.15.15 0 00.039.108.125.125 0 00.098.041z"
      transform="matrix(.26108 0 0 .26108 -78.555 -286.414) translate(753.073 1261.81) translate(298.88) scale(128)"
    ></path>
    <path
      fill="#fff"
      fillRule="nonzero"
      d="M.614-.538V0H.466v-.064a.224.224 0 01-.167.072.227.227 0 01-.168-.061C.09-.094.069-.154.069-.234v-.304h.156v.281c0 .087.036.13.109.13a.116.116 0 00.09-.037c.023-.024.034-.06.034-.108v-.266h.156z"
      transform="matrix(.26108 0 0 .26108 -78.555 -286.414) translate(753.073 1261.81) translate(387.2) scale(128)"
    ></path>
    <path
      fill="#fff"
      fillRule="nonzero"
      d="M.072-.538h.156V0H.072v-.538zM.15-.613a.097.097 0 01-.07-.025A.081.081 0 01.053-.7c0-.025.009-.045.027-.062a.097.097 0 01.07-.025.1.1 0 01.07.024.077.077 0 01.027.06.084.084 0 01-.027.064.098.098 0 01-.07.026z"
      transform="matrix(.26108 0 0 .26108 -78.555 -286.414) translate(753.073 1261.81) translate(475.136) scale(128)"
    ></path>
    <path
      fill="#fff"
      fillRule="nonzero"
      d="M0.072 -0.742H0.22799999999999998V0H0.072z"
      transform="matrix(.26108 0 0 .26108 -78.555 -286.414) translate(753.073 1261.81) translate(513.664) scale(128)"
    ></path>
    <path
      fill="#fff"
      fillRule="nonzero"
      d="M.62-.742V0H.471v-.062c-.039.047-.095.07-.168.07a.273.273 0 01-.137-.034.245.245 0 01-.097-.097.3.3 0 01-.035-.146.3.3 0 01.035-.146.245.245 0 01.097-.097.273.273 0 01.137-.034c.069 0 .122.022.161.065v-.261H.62zM.33-.12a.128.128 0 00.098-.041.15.15 0 00.039-.108.152.152 0 00-.039-.109.13.13 0 00-.098-.04.131.131 0 00-.099.04.152.152 0 00-.039.109.15.15 0 00.039.108.128.128 0 00.099.041z"
      transform="matrix(.26108 0 0 .26108 -78.555 -286.414) translate(753.073 1261.81) translate(552.192) scale(128)"
    ></path>
    <path
      fill="#fff"
      fillRule="nonzero"
      d="M.599-.267l-.003.042H.189c.007.033.025.06.052.079a.173.173 0 00.102.029.182.182 0 00.135-.052l.083.09C.51-.021.436.008.339.008a.347.347 0 01-.161-.036.251.251 0 01-.108-.098.275.275 0 01-.038-.143c0-.053.013-.1.038-.143a.259.259 0 01.103-.099.306.306 0 01.146-.035c.053 0 .1.011.143.034a.255.255 0 01.101.097.298.298 0 01.036.148zM.32-.428a.135.135 0 00-.089.03.125.125 0 00-.044.082h.265a.13.13 0 00-.044-.082.132.132 0 00-.088-.03z"
      transform="matrix(.26108 0 0 .26108 -78.555 -286.414) translate(753.073 1261.81) translate(640.768) scale(128)"
    ></path>
    <path
      fill="#fff"
      fillRule="nonzero"
      d="M.221-.467a.173.173 0 01.076-.059.283.283 0 01.109-.02v.144L.371-.404c-.045 0-.08.012-.105.037-.025.026-.038.063-.038.113V0H.072v-.538h.149v.071z"
      transform="matrix(.26108 0 0 .26108 -78.555 -286.414) translate(753.073 1261.81) translate(721.536) scale(128)"
    ></path>
  </svg>
);
