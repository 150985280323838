import React from 'react'
import Container from 'components/common/Container'
import NavbarLinks from '../NavbarLinks'
import { LogoWrapper, Wrapper } from './styles'
import LogoLong from 'components/illustrations/LogoLong'

export default () => (
  <Wrapper as={Container}>
    <LogoWrapper>
      <a href="/" ><LogoLong/></a>
    </LogoWrapper>
    <NavbarLinks desktop/>
  </Wrapper>
);
