import styled from "styled-components";

export const Links = styled.div`
  display: flex;
  align-items: center;
  color: white;

  svg {
    width: 24px;
  }

  a {
    margin: 0 0.5rem;
    color: white;

    &:first-child,
    &:last-child {
      margin: 0;
    }
  }

  span {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;
