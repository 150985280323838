import styled from "styled-components";
import colors from "tokens/colors";

export const Wrapper = styled.div`
  padding: 28rem 0 4rem 0;
  background-image: ${({ color = colors.primaryOverlay }) =>
    `url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' viewBox='0 0 1922 449' fill='%23${color}' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'%3E%3Cg%3E%3Cpath d='M154.607,203.032C154.607,203.032 305.006,68.491 653.506,167.791C1002.01,267.091 1309.99,164.427 1309.99,164.427C1309.99,164.427 1797.3,31 1920.8,45.3C1920.9,45.4 1920.5,448.5 1920.8,447.7L1124.2,434.7L152,448.1L154.607,203.032Z' style='fill:rgb(0,153,255);fill-opacity:0.4;fill-rule:nonzero;'/%3E%3Cpath d='M0,143.1C0,143.1 155.898,53.974 504.398,153.274C852.898,252.574 1097.12,164.341 1365.17,79.95C1543.88,23.686 1798.5,-11 1922,3.3C1922.1,3.4 1921.7,406.5 1922,405.7L972.2,392.7L0,406.1L0,143.1Z' style='fill:rgb(0,153,255);fill-opacity:0.2;fill-rule:nonzero;'/%3E%3Cpath d='M0.4,191.7C96.9,168.7 295.6,138.4 540.3,208.1C888.7,307.4 1066.64,238.424 1242.3,180.956C1444.84,114.697 1639.4,11.8 1877.8,111.3C1892.83,117.474 1907.23,125.076 1920.8,134C1921.2,135 1920.8,448.7 1920.8,447.4C1920.5,447.1 0.4,448 0.4,448C0.4,448 0.6,191.7 0.4,191.7Z' style='fill:rgb(0,153,255);fill-rule:nonzero;'/%3E%3C/g%3E%3C/svg%3E%0A")`};
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;

  @media (max-width: 1960px) {
    padding: 14rem 0 4rem;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: 680px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
`;

export const Details = styled.div`
  color: white;
  @media (max-width: 680px) {
    margin-bottom: 2rem;
  }
  
  h2 {
    text-transform: uppercase;
  }
`;
