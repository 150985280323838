import React from 'react'
import { Links } from './styles'

export default () => (
  <Links>
    <a
      href="/privacy-policy"
      aria-label="Privacy policy"
    >
      PRIVACY POLICY
    </a>
    <span>|</span>
    <a
      href="/terms-of-service"
      aria-label="Terms of service"
    >
      TERMS OF SERVICE
    </a>
  </Links>
);
